import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import * as TooltipStories from '@stories/tooltip.stories.js';
import { Tooltip, TooltipIcon, TooltipMessage } from '@jsluna/labs';
import { TextInputField } from '@jsluna/form';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Example = makeShortcode("Example");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "how-to-use-a-tooltip",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-a-tooltip",
        "aria-label": "how to use a tooltip permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use a tooltip`}</h2>
    <p>{`When using a tooltip:`}</p>
    <ul>
      <li parentName="ul">{`Only include supporting information that’s not essential to progress`}</li>
      <li parentName="ul">{`Make sure the tooltip doesn’t obscure the element it’s explaining when opened`}</li>
      <li parentName="ul">{`Use a live region to make sure that the content of the tooltip is announced to screen readers`}</li>
      <li parentName="ul">{`Make sure the tooltip icon has an accessible name`}</li>
    </ul>
    <p>{`If you need to include lots of information or interactive elements like buttons, use a modal instead.`}</p>
    <h2 {...{
      "id": "types-of-tooltip",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-tooltip",
        "aria-label": "types of tooltip permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of tooltip`}</h2>
    <h3>{`Standard`}</h3>
    <p>{`A standard tooltip is triggered on click or tap. Supporting content appears to the right of the icon.`}</p>
    <Example stories={TooltipStories} component={'Standard'} mdxType="Example" />
    <h4>{`Custom position (for screens larger than 720px)`}</h4>
    <p>{`A custom position can be set to change where the supporting content appears. Use this to stop the content covering up the element that it’s explaining.`}</p>
    <Example stories={TooltipStories} component={'Custom'} mdxType="Example" />
    <h4>{`Within a form field`}</h4>
    <p>{`Tooltips are often included next to form labels.`}</p>
    <p>{`If a majority of users are likely to benefit from some content, don’t hide it in a tooltip. Instead, use tooltips to avoid cluttering screens with supporting content that’s only helpful for a small number of users.`}</p>
    <Example stories={TooltipStories} component={'WithinForm'} mdxType="Example" />
    <h3>{`Behaviour on screens smaller than 720px`}</h3>
    <p>{`On screens smaller than 720px in width, the tooltip content will not overlay other content on the page when it is opened.`}</p>
    <p>{`Instead, it will open below the icon and push other content on the page down. This is to stop the tooltip content obscuring other parts of the page.`}</p>
    <p>{`On smaller screen sizes, the tooltip icon must be tapped again to close it.`}</p>
    <Example stories={TooltipStories} component={'WithinForm'} mdxType="Example" />
    <h3>{`Copy guidelines`}</h3>
    <p>{`Keep tooltip content short.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <TooltipStories.DoTooltipOne />
  </Do>
  <Dont center mdxType="Dont">
    <TooltipStories.DontTooltipOne />
  </Dont>
    </Guideline>
    <p>{`If information’s essential to progress, don’t hide it in a tooltip.`}</p>
    <Guideline mdxType="Guideline">
  <Do center mdxType="Do">
    <TextInputField name="text-input-field-4" info="For security reasons, this has to match the address that’s linked to your card." label="Delivery address" mdxType="TextInputField" />
  </Do>
  <Dont center mdxType="Dont">
    <TooltipStories.DontTooltipTwo />
  </Dont>
    </Guideline>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using toggle buttons or give you a
  helping hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      